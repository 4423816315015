var generic = generic || {};
var Mustache = Mustache || {};
var site = site || {};
var Unison = Unison || {};

(function ($, Unison) {
  var $window = $(window);
  var isUnisonAvailable = Unison.fetch !== undefined;

  // Default breakpoints if any of the brands don't have Unison support
  var bps = {
    small: '0',
    portrait: '768px',
    landscape: '1024px'
  };

  var bp = {
    width: $window.width()
  };

  Drupal.behaviors.fullSizeSampleV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var signedIn =
        Number(site.userInfoCookie.getValue('signed_in')) === 1 ||
        Number(site.userInfoCookie.getValue('csr_logged_in')) === 1;
      // full size samples needs to be populated only for signed in users
      if (!signedIn) {
        return false;
      }
      bps = isUnisonAvailable ? Unison.fetch.all() : bps;
      bp = isUnisonAvailable ? Unison.fetch.now() : bp;
      var landscapeBreakPoint = parseInt(bps.landscape, 10);
      var isMobile = parseInt(bp.width, 10) < landscapeBreakPoint;
      var htmlLangDir = $('html').attr('dir');
      var isRTL = htmlLangDir && htmlLangDir === 'rtl';
      var $template = $('.js-elc-full-size-sample-v1', context);
      var $carouselContainer = $('.js-full-size-samples-carousel', $template);
      var $closeIcon = $('.js-full-size-sample-close', $template);

      /**
       * Function: updateWrapperClass
       *
       * Add/Remove classname for mobile viewport
       * @returns {boolean} false
       */
      var updateWrapperClass = function () {
        $template.each(function () {
          var $this = $(this);
          if (isMobile) {
            $this.addClass('elc-full-size-sample--mobile');
          } else {
            $this.removeClass('elc-full-size-sample--mobile');
          }
          $('.js-full-size-samples-carousel', $this).removeAttr('data-slick-refreshed');
        });
      };
      updateWrapperClass();

      /**
       * Function: buildCarousel
       *
       * Build carousel for the number of sample items
       * @param {string} carouselItems - Carousel items content
       * @returns {boolean} false
       */
      var buildCarousel = function (carouselItems) {
        var itemsPerSlideLarge;
        var itemsPerSlideSmall;
        // Build all full size carousels on the same page
        $carouselContainer.each(function () {
          var $eachCarousel = $(this);
          itemsPerSlideLarge = parseInt($eachCarousel.attr('data-pc-carousel-slides-to-show'), 10) || 1;
          itemsPerSlideSmall = parseInt($eachCarousel.attr('data-mobile-carousel-slides-to-show'), 10) || 1;
          var $carouselControls = $eachCarousel.siblings('.carousel-controls');
          var $categoryCta = $eachCarousel.closest($template).find('.js-full-size-sample-category-cta');
          $eachCarousel.append(carouselItems);
          $eachCarousel.on('init afterChange', function () {
            var $this = $(this);
            var carouselClassName =
              $this.find('.slick-dots').length === 0
                ? 'elc-full-size-sample__content-samples-carousel--single-item'
                : '';
            var $activeSlide = $this.find('.slick-active');
            var categoryName = $activeSlide.attr('data-category-name');
            var categoryUrl = $activeSlide.attr('data-category-url');
            updateCategoryCta(categoryName, categoryUrl, $categoryCta);
            $this.addClass(carouselClassName);
          });
          if (typeof $.fn.slick === 'function') {
            $eachCarousel.slick({
              dots: true,
              rtl: isRTL,
              infinite: true,
              arrows: true,
              speed: 300,
              slidesToShow: itemsPerSlideLarge,
              slidesToScroll: 1,
              appendArrows: $carouselControls,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: itemsPerSlideLarge,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: itemsPerSlideSmall,
                    slidesToScroll: 1
                  }
                }
              ]
            });
          }

          $('.js-full-size-sample-shop-now', $eachCarousel).on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            if (typeof $.fn.slick === 'undefined') {
              return;
            }
            var currentSlickIndex = $this.closest('.js-full-size-samples-carousel').slick('slickCurrentSlide');
            // After firing analytics event, lets proceed to SPP redirect
            $this.siblings('.js-full-size-sample-link').get(0).click();
          });
        });
      };

      /**
       * Function: updateCategoryCta
       *
       * Update category CTA copy and its URL based on the
       * current carousel item
       * @param {string} categoryName - Category name
       * @param {string} categoryUrl - Category CTA URL
       * @param {object} $categoryCta - Category CTA
       * @returns {boolean} false
       */
      var updateCategoryCta = function (categoryName, categoryUrl, $categoryCta) {
        $categoryCta
          .attr('href', categoryUrl)
          .html($categoryCta.attr('data-copy').replace('::CATEGORY_NAME::', categoryName));
      };


      /**
       * Function: getFullSizeSamples
       *
       * Get recent samples ordered data through RPC call,
       * and build carousel
       * @returns {boolean} false
       */
      var getFullSizeSamples = function () {
        var $carouselItemTemplate = $('script.inline-template[path="elc_full_size_sample_item"]', context);
        var carouselItemPartial = $carouselItemTemplate.html();
        if (generic.jsonrpc) {
          generic.jsonrpc.fetch({
            method: 'user.recentFullSizeSampleData',
            params: [{}],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getValue() || [];
              var responseItems = '';
              if (response.length) {
                $template.removeClass('hidden');
                $.each(response, function (index, data) {
                  responseItems += Mustache.render(carouselItemPartial, data);
                });
                buildCarousel(responseItems);
              } else {
                $template.addClass('hidden');
              }
            }
          });
        }
      };
      getFullSizeSamples();

      // Close the full size content
      if ($closeIcon) {
        $closeIcon.once().on('click', function () {
          $(this).closest($template).addClass('hidden');
        });
      }

      // Responsiveness for mobile
      if (isUnisonAvailable) {
        Unison.on('change', function (bp) {
          isMobile = parseInt(bp.width, 10) < landscapeBreakPoint;
          updateWrapperClass();
        });
      } else {
        if (typeof _ === 'function' && typeof _.debounce === 'function') {
          $window.on(
            'resize',
            _.debounce(function () {
              isMobile = $window.width() < landscapeBreakPoint;
              updateWrapperClass();
            }, 250)
          );
        }
      }

    }
  };
})(jQuery, Unison);
